import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);
const routes = [
  { name: "index", path: "/", redirect: navigator.language.includes("zh") ? "/zh" : "/en" },
  {
    name: "layout",
    path: "/layout",
    component: () => import("@/views/layout/layout.vue"),
    children: [
      { name: "/zh", path: "/zh", nav: true, component: () => import("@/views/home/home.vue") },
      { name: "/zh/website", path: "/zh/website", nav: true, component: () => import("@/views/home/home.vue") },
      { name: "/zh/hotProduct", path: "/zh/hotProduct", component: () => import("@/views/hotProduct/hotProduct.vue") },
      { name: "/zh/hotNews", path: "/zh/hotNews", component: () => import("@/views/hotNews/hotNews.vue") },
      { name: "/zh/game", path: "/zh/game", component: () => import("@/views/computers/computers.vue") },
      { name: "/zh/software", path: "/zh/software", component: () => import("@/views/software/software.vue") },
      { name: "/zh/netdisc", path: "/zh/netdisc", component: () => import("@/views/netdisc/netdisc.vue") },
      { name: "/zh/detail", path: "/zh/detail/:type/:id", component: () => import("@/views/detail/detail.vue") },
      { name: "/zh/tool", path: "/zh/tool", component: () => import("@/views/tool/tool.vue") },
      { name: "/zh/imgToPdf", path: "/zh/imgToPdf", component: () => import("@/views/imgToPdf/imgToPdf.vue") },
      { name: "/zh/QRCode", path: "/zh/QRCode", component: () => import("@/views/QRCode/QRCode.vue") },
      { name: "/zh/imgCompress", path: "/zh/imgCompress", component: () => import("@/views/imgCompress/imgCompress.vue") },
      { name: "/zh/base64", path: "/zh/base64", component: () => import("@/views/base64/base64.vue") },
      { name: "/zh/md5", path: "/zh/md5", component: () => import("@/views/md5/md5.vue") },

      { name: "/en", path: "/en", nav: true, component: () => import("@/views/home/home.vue") },
      { name: "/en/website", path: "/en/website", nav: true, component: () => import("@/views/home/home.vue") },
      { name: "/en/hotProduct", path: "/en/hotProduct", component: () => import("@/views/hotProduct/hotProduct.vue") },
      { name: "/en/hotNews", path: "/en/hotNews", component: () => import("@/views/hotNews/hotNews.vue") },
      { name: "/en/game", path: "/en/game", component: () => import("@/views/computers/computers.vue") },
      { name: "/en/software", path: "/en/software", component: () => import("@/views/software/software.vue") },
      { name: "/en/netdisc", path: "/en/netdisc", component: () => import("@/views/netdisc/netdisc.vue") },
      { name: "/en/detail", path: "/en/detail/:type/:id", component: () => import("@/views/detail/detail.vue") },
      { name: "/en/tool", path: "/en/tool", component: () => import("@/views/tool/tool.vue") },
      { name: "/en/imgToPdf", path: "/en/imgToPdf", component: () => import("@/views/imgToPdf/imgToPdf.vue") },
      { name: "/en/QRCode", path: "/en/QRCode", component: () => import("@/views/QRCode/QRCode.vue") },
      { name: "/en/imgCompress", path: "/en/imgCompress", component: () => import("@/views/imgCompress/imgCompress.vue") },
      { name: "/en/base64", path: "/en/base64", component: () => import("@/views/base64/base64.vue") },
      { name: "/en/md5", path: "/en/md5", component: () => import("@/views/md5/md5.vue") },
    ],
  },
];
const router = new VueRouter({
  routes,
  mode: "history",
});

export default router;
