import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueI18n from "vue-i18n";
import "./css/cssVar.scss";
import "./css/main.scss";
import VueMeta from "vue-meta";
import zh from "./utils/configData";
import en from "./utils/enData";

Vue.use(VueI18n);
Vue.use(VueMeta);
Vue.config.productionTip = false;
const i18n = new VueI18n({
  locale: "en",
  messages: {
    zh: zh,
    en: en,
  },
});
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
  mounted() {
    document.dispatchEvent(new Event("render-event"));
  },
}).$mount("#app");
